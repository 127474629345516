.grim-protocol-section-container {
  position: relative;
  padding-top: 5vw;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 50vw;
  z-index: 1;
  background-image: url("./images/blacksmithshop.png");
  background-size: cover;
  background-position: center;
  border-bottom: .5vh solid;
  border-top: .5vh solid;
  border-image: radial-gradient(circle at center, gold 0%, #000000 90%);
  border-image-slice: 1;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.gradient-container {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 40%, transparent 80%);
  z-index: -1;
}

.farming-description {
  font-size: 4rem;
  text-align: center;
  color: rgb(191, 183, 147, 0.849);
}

.dynamic-text {
  font-size: 3rem;
  text-align: center;
  color: rgb(246, 248, 221, 0.849);
  position: absolute;
  top: -3vw;
  white-space: nowrap;
}

.blah-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vw;
  background-color: transparent;
  z-index: 3;
}

.farming-basics-header {
  font-size: 6rem;
  text-align: center;
  color: rgb(246, 248, 221, 0.849);
}

.left-side {
  display: flex;
  flex-direction: column;
  padding-right: 10vw;
  width: 30vw;
}

.click-stuff {
  font-size: 6rem;
  text-align: center;
  color: rgb(246, 248, 221, 0.849);
  margin-bottom: 15px;
}

.item-box {
  position: relative;
  border: 1px solid #000000;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1vw;
  width: 50vw;
  height: 50vw;
  margin: 0 auto;
}

.right-side {
  display: flex;
  flex-direction: column;
  width: 40vw;
}

.container-right {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-container {
  width: 30vw;
  height: auto;
  position: relative;
}

.right-side-champ {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid #000000;
}

.right-side-hair {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-hat {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-shoulder {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-chest {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-gloves {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-belt {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-pants {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto
}

.right-side-boots {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-ring {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-ring2 {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-amulet {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-main {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.right-side-off {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 100%;
  height: auto;
}

.boxes-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.boxes-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
}

.hat-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

.shoulder-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);

}

.chest-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

.gloves-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

.belt-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

.pants-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

.boots-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

.ring-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

.ring2-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

.amulet-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

.bottom-boxes {
  display: flex;
  justify-content: space-evenly;
  position: absolute;
  bottom: -5vw;
  width: 100%;
}

.main-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

.off-box {
  width: 5vw;
  height: 5vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid rgb(68, 36, 15);
  background-color: transparent;
  box-sizing: border-box;
  background-color: rgb(57, 57, 57);
}

@media (max-width: 359px) {

  .boxes-right {
    padding-left: 2px;
  }

  .item-box {
    padding: 5vw;
    width: 60%;
    height: 20vw;
  }

  .farming-basics-header {
    font-size: .75rem;
  }

  .farming-description {
    font-size: .25rem;
  }

  .click-stuff {
    font-size: .5rem;
  }

  .dynamic-text {
    font-size: .25rem;
    top: -4vw;
  }

  .item-box img {
    width: 5vw;
    height: auto;
  }

  .image-container {
    width: 30vw;
    height: auto;
  }

  .hat-box {
    width: 5vw;
    height: 5vw;
  }

  .shoulder-box {
    width: 5vw;
    height: 5vw;
  }

  .chest-box {
    width: 5vw;
    height: 5vw;
  }

  .gloves-box {
    width: 5vw;
    height: 5vw;
  }

  .belt-box {
    width: 5vw;
    height: 5vw;
  }

  .pants-box {
    width: 5vw;
    height: 5vw;
  }

  .boots-box {
    width: 5vw;
    height: 5vw;
  }

  .ring-box {
    width: 5vw;
    height: 5vw;
  }

  .ring2-box {
    width: 5vw;
    height: 5vw;
  }

  .amulet-box {
    width: 5vw;
    height: 5vw;
  }

  .main-box {
    width: 5vw;
    height: 5vw;
  }

  .off-box {
    width: 5vw;
    height: 5vw;
  }
}

@media (min-width: 360px) and (max-width: 600px) {

  .boxes-right {
    padding-left: 2px;
  }

  .item-box {
    padding: 5vw;
    width: 20vw;
    height: 20vw;
  }

  .farming-basics-header {
    font-size: 1.5rem;
  }

  .farming-description {
    font-size: .5rem;
  }

  .click-stuff {
    font-size: 1rem;
  }

  .dynamic-text {
    font-size: .5rem;
    top: -5vw;
  }

  .item-box img {
    width: 5vw;
    height: auto;
  }

  .image-container {
    width: 30vw;
    height: auto;
  }

  .hat-box {
    width: 5vw;
    height: 5vw;
  }

  .shoulder-box {
    width: 5vw;
    height: 5vw;
  }

  .chest-box {
    width: 5vw;
    height: 5vw;
  }

  .gloves-box {
    width: 5vw;
    height: 5vw;
  }

  .belt-box {
    width: 5vw;
    height: 5vw;
  }

  .pants-box {
    width: 5vw;
    height: 5vw;
  }

  .boots-box {
    width: 5vw;
    height: 5vw;
  }

  .ring-box {
    width: 5vw;
    height: 5vw;
  }

  .ring2-box {
    width: 5vw;
    height: 5vw;
  }

  .amulet-box {
    width: 5vw;
    height: 5vw;
  }

  .main-box {
    width: 5vw;
    height: 5vw;
  }

  .off-box {
    width: 5vw;
    height: 5vw;
  }
}

@media (min-width: 601px) and (max-width: 1279px) {

  .boxes-right {
    padding-left: 2px;
  }

  .item-box {
    padding: 5vw;
    width: 20vw;
    height: 20vw;
  }

  .farming-basics-header {
    font-size: 3rem;
  }

  .farming-description {
    font-size: 1rem;
  }

  .click-stuff {
    font-size: 2rem;
  }

  .dynamic-text {
    font-size: 1.5rem;
    top: -7.5vw;
  }

  .item-box img {
    width: 5vw;
    height: auto;
  }

  .image-container {
    width: 30vw;
    height: auto;
  }

  .hat-box {
    width: 5vw;
    height: 5vw;
  }

  .shoulder-box {
    width: 5vw;
    height: 5vw;
  }

  .chest-box {
    width: 5vw;
    height: 5vw;
  }

  .gloves-box {
    width: 5vw;
    height: 5vw;
  }

  .belt-box {
    width: 5vw;
    height: 5vw;
  }

  .pants-box {
    width: 5vw;
    height: 5vw;
  }

  .boots-box {
    width: 5vw;
    height: 5vw;
  }

  .ring-box {
    width: 5vw;
    height: 5vw;
  }

  .ring2-box {
    width: 5vw;
    height: 5vw;
  }

  .amulet-box {
    width: 5vw;
    height: 5vw;
  }

  .main-box {
    width: 5vw;
    height: 5vw;
  }

  .off-box {
    width: 5vw;
    height: 5vw;
  }


}

@media (min-width: 1280px) {

  .boxes-right {
    padding-left: 2px;
  }

  .item-box {
    padding: 5vw;
    width: 20vw;
    height: 20vw;
  }

  .farming-basics-header {
    font-size: 4.5rem;
  }

  .farming-description {
    font-size: 1.5rem;
  }

  .click-stuff {
    font-size: 3rem;
  }

  .dynamic-text {
    font-size: 3rem;
    top: -8vw;
  }

  .item-box img {
    width: 5vw;
    height: auto;
  }

  .image-container {
    width: 30vw;
    height: auto;
  }

  .hat-box {
    width: 5vw;
    height: 5vw;
  }

  .shoulder-box {
    width: 5vw;
    height: 5vw;
  }

  .chest-box {
    width: 5vw;
    height: 5vw;
  }

  .gloves-box {
    width: 5vw;
    height: 5vw;
  }

  .belt-box {
    width: 5vw;
    height: 5vw;
  }

  .pants-box {
    width: 5vw;
    height: 5vw;
  }

  .boots-box {
    width: 5vw;
    height: 5vw;
  }

  .ring-box {
    width: 5vw;
    height: 5vw;
  }

  .ring2-box {
    width: 5vw;
    height: 5vw;
  }

  .amulet-box {
    width: 5vw;
    height: 5vw;
  }

  .main-box {
    width: 5vw;
    height: 5vw;
  }

  .off-box {
    width: 5vw;
    height: 5vw;
  }
}