.grim-lore-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-behavior: smooth;
  overflow: hidden;
  height: 70vw;
  background-color: #000000;
  z-index: 1;
  border-top: .5vh solid;
  border-image: radial-gradient(circle at center, gold 0%, #000000 90%);
  border-image-slice: 1;

}

.book-image-container {
  margin-top: 5vw;
  margin-bottom: 5vw;
  position: relative;
  display: inline-block;
  z-index: 1;
  transform: scale(1)
}

.flip-book-container {
  position: absolute;
  top: 12px;
  left: 60px;
  width: 100%;
  height: 100%;
  z-index: 2;
  transform: scale(1)
}

.p5-background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.p5-background-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 500px) {
  .book-image-container {
    transform: scale(.2);
  }
}

@media (min-width: 500px) and (max-width: 799px) {
  .book-image-container {
    transform: scale(.3);
  }
}

@media (min-width: 800px) and (max-width: 949px) {
  .book-image-container {
    transform: scale(.5);
  }
}

@media (min-width: 950px) and (max-width: 1279px) {
  .book-image-container {
    transform: scale(.6);
  }
}


@media (min-width: 1280px) and (max-width: 1699px) {
  .book-image-container {
    transform: scale(.8);
  }
}

@media (min-width: 1700px) {
  .book-image-container {
    transform: scale(1);
  }
}