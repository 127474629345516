.grim-prot2-section-container {
  position: relative;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 30vw;
  background-color: #08020c;
  z-index: 1;
  padding-bottom: 5px;
  background-image: url("./images/SplitScenes.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 3vh;
  padding-bottom: 3vh;
}

.grim-prot2-section-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #000000, #000000);
  opacity: 0.95;
  z-index: -1;
}

.protocol-text-wrapper {
  margin-bottom: 20px;
}

.protocol-text {
  font-size: 6rem;
  text-align: center;
  color: rgb(246, 248, 221, 0.849);
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  max-width: 80%;
  width: 100%;
  margin: 0 auto;
}

.interop-problem {
  font-size: 4rem;
  text-align: center;
  color: rgb(191, 183, 147, 0.849);
  grid-row: 1 / span 1;
  grid-column: 1 / span 1;
}

.arrow-svg {
  width: 10vw;
  height: auto;
}

.arrow-icon1 {
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;
  margin-top: 4vw;
}

.arrow-icon2 {
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;
  margin-top: 4vw;
}

.arrow-icon3 {
  grid-row: 3 / span 1;
  grid-column: 2 / span 1;
  margin-top: 4vw;
}

.interop-solution {
  font-size: 4rem;
  text-align: center;
  color: rgb(191, 183, 147, 0.849);
  grid-row: 1 / span 1;
  grid-column: 3 / span 1;
}

.cost-problem {
  font-size: 4rem;
  text-align: center;
  color: rgb(191, 183, 147, 0.849);
  grid-row: 2 / span 1;
  grid-column: 1 / span 1;
}

.cost-solution {
  font-size: 4rem;
  text-align: center;
  color: rgb(191, 183, 147, 0.849);
  grid-row: 2 / span 1;
  grid-column: 3 / span 1;
}


.pay-to-win-problem {
  font-size: 4rem;
  text-align: center;
  color: rgb(191, 183, 147, 0.849);
  grid-row: 3 / span 1;
  grid-column: 1 / span 1;
}

.pay-to-win-solution {
  font-size: 4rem;
  text-align: center;
  color: rgb(191, 183, 147, 0.849);
  grid-row: 3 / span 1;
  grid-column: 3 / span 1;
}



.screenshot {
  max-width: 100%;
  height: auto;
}

@media (max-width: 359px) {
  .protocol-text {
    font-size: .75rem;
  }

  .interop-problem {
    font-size: .25rem;
  }

  .interop-solution {
    font-size: .25rem;
  }

  .cost-problem {
    font-size: .25rem;
  }

  .cost-solution {
    font-size: .25rem;
  }

  .pay-to-win-problem {
    font-size: .25rem;
  }

  .pay-to-win-solution {
    font-size: .25rem;
  }

  .arrow-svg {
    width: 10vw;
    height: auto;
  }
}

@media (min-width: 360px) and (max-width: 600px) {
  .protocol-text {
    font-size: 1.5rem;
  }

  .interop-problem {
    font-size: .5rem;
  }

  .interop-solution {
    font-size: .5rem;
  }

  .cost-problem {
    font-size: .5rem;
  }

  .cost-solution {
    font-size: .5rem;
  }

  .pay-to-win-problem {
    font-size: .5rem;
  }

  .pay-to-win-solution {
    font-size: .5rem;
  }

  .arrow-svg {
    width: 10vw;
    height: auto;
  }
}

@media (min-width: 601px) and (max-width: 1279px) {
  .protocol-text {
    font-size: 3rem;
  }

  .interop-problem {
    font-size: 1rem;
  }

  .interop-solution {
    font-size: 1rem;
  }

  .cost-problem {
    font-size: 1rem;
  }

  .cost-solution {
    font-size: 1rem;
  }

  .pay-to-win-problem {
    font-size: 1rem;
  }

  .pay-to-win-solution {
    font-size: 1rem;
  }

  .arrow-svg {
    width: 10vw;
    height: auto;
  }
}

@media (min-width: 1280px) {
  .protocol-text {
    font-size: 4.5rem;
  }

  .interop-problem {
    font-size: 1.5rem;
  }

  .interop-solution {
    font-size: 1.5rem;
  }

  .cost-problem {
    font-size: 1.5rem;
  }

  .cost-solution {
    font-size: 1.5rem;
  }

  .pay-to-win-problem {
    font-size: 1.5rem;
  }

  .pay-to-win-solution {
    font-size: 1.5rem;
  }

  .arrow-svg {
    width: 200px;
    height: auto;
  }

  .arrow-icon1 {
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
    margin-top: .1vw;
  }

  .arrow-icon2 {
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
    margin-top: .1vw;
  }

  .arrow-icon3 {
    grid-row: 3 / span 1;
    grid-column: 2 / span 1;
    margin-top: .1vw;
  }
}