.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rbga (0, 0, 0, 0.0);
  padding: 0.5rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar__logo img {
  height: 150px;
  width: auto;
}

.navbar__links {
  display: flex;
  margin-left: 20px;
}

.navbar__links button {
  font-family: 'car', sans-serif;
  font-size: 35px;
  text-decoration: none;
  padding: 6px 15px;
  color: rgba(246, 248, 221, 0.849);
  border-radius: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.navbar__links .active {
  background-color: rgb(246, 248, 221, 0.849);
  color: black;
}

.burger-menu {
  display: none;
  position: relative;
  width: 30px;
  height: 21px;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
}

.burger-menu span {
  display: block;
  height: 3px;
  background-color: rgba(54, 15, 15, 0.849);
  width: 100%;
}


@media (max-width: 1520px) {
  .navbar__links {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    flex-direction: column;
    width: 200px;
    display: none;
  }

  .navbar__links.open {
    display: flex;
  }

  .burger-menu {
    display: flex;
  }
}

@media (max-width: 359px) {
  .navbar__logo img {
    height: 40px;
    width: auto;
  }
}

@media (min-width: 360px) and (max-width: 600px) {
  .navbar__logo img {
    height: 60px;
    width: auto;
  }
}


@media (min-width: 601px) and (max-width: 1279px) {
  .navbar__logo img {
    height: 100px;
    width: auto;
  }
}

@media (min-width: 1280px) {
  .navbar__logo img {
    height: 150px;
    width: auto;
  }
}