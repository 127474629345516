.grim-scroll {
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 100vh;
  height: 0px;
  background: linear-gradient(#6191d8, #03030c);
  position: relative;
  z-index: 1;
}

.grim-text {
  font-size: 25rem;
  z-index: -2;
}

#stars {
  z-index: -3;
}

.moving-parts {
  position: relative;
  width: 100%;
  height: 100%;
}

.moving-parts img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 3;
}

.moving-parts div {
  position: relative;
  top: 0%;
  left: 0%;
  font-size: 10rem;
  padding-right: 500px;
  font-weight: bold;
  text-align: center;
  transition: color 0.5s ease;
}

@media (max-width: 359px) {
  .moving-parts div {
    font-size: 5rem;
    padding-right: 20%;
  }

  .responsive-img {
    max-width: 100%;
    max-height: 100%;
  }

}

@media (min-width: 360px) and (max-width: 600px) {
  .moving-parts div {
    font-size: 5rem;
    padding-right: 10%;
  }

  .responsive-img {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 601px) and (max-width: 1279px) {
  .moving-parts div {
    font-size: 10rem;
    padding-right: 15%;
  }

  .responsive-img {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1280px) {
  .moving-parts div {
    font-size: 15rem;
    padding-right: 15%;
  }

  .responsive-img {
    width: 100%;
    height: 100%;
  }
}