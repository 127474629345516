.summon-section-container {
  position: relative;
  padding-top: 0px;
  scroll-behavior: smooth;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 30vw;
  max-height: calc(70vh - 10% - 3rem);
  background-color: rgb(0, 0, 0);
  z-index: 2;
  border-top: .5vh solid;
  border-bottom: .5vh solid;
  border-image: radial-gradient(circle at center, gold 0%, #000000 90%);
  border-image-slice: 1;
  padding-bottom: 3vh;
  padding-top: 3vh;
}

.content-wrapper {
  margin-top: 5%;
}

.summon-section-container h2 {
  margin-top: 4vw;
  font-size: 3rem;
  color: rgba(246, 248, 221, 0.849);
  text-align: center;
}

.summon-section-container p {
  font-size: 2rem;
  margin-left: 20%;
  margin-right: 20%;
  color: rgba(191, 183, 147, 0.849);
  text-align: center;
}

.circle-content-wrapper {
  display: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 3rem
}

.circle-image-container {
  flex: 1;
  height: auto;
  width: 50%;
  margin-left: 50%;
  position: relative;
}

.circle-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 50%;
  margin-left: 10%
}

.circle-buttons-container button::before {
  content: '•';
  position: absolute;
  left: 0;
  color: rgba(246, 248, 221, 0.849);
  font-size: 2rem;
  margin-left: 9%;
}

.circle-buttons-container button {
  font-size: 1rem;
  background: none;
  color: rgba(246, 248, 221, 0.849);
  border: none;
  cursor: pointer;
  margin-bottom: 1rem;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  white-space: nowrap;
}

.opensea-link-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  margin-left: 10%;
}

.opensea-link-container img {
  width: auto;
  height: 3vw;
  cursor: pointer;
}

.circle-bg {
  position: absolute;
  top: 0%;
  left: 60%;
  transform: translate(-50%, -70%);
  opacity: 0.5;
  z-index: -1;
  max-width: 150%;
  height: auto;
  mask-image: radial-gradient(circle at center, black 40%, transparent 70%);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
  visibility: hidden;
}

.circle-bg.visible {
  visibility: visible;
}

.types-span {
  white-space: nowrap;
}

.button-inner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.circle-buttons-container button:hover .circle-name {
  text-decoration: underline;
}

@media (max-width: 359px) {

  .summon-section-container {
    min-height: 40vw;
    max-height: calc(70vh - 10% - 3rem);
  }

  .summon-section-container h2 {
    font-size: 0..75rem;
  }

  .summon-section-container p {
    font-size: 0.25rem;
  }

  .circle-buttons-container button {
    font-size: 0.5rem;
    background: none;
    color: rgba(246, 248, 221, 0.849);
    border: none;
    cursor: pointer;
    margin-bottom: 1rem;
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    white-space: nowrap;
  }

  .circle-buttons-container button::before {
    content: '•';
    position: absolute;
    left: 0;
    color: rgba(246, 248, 221, 0.849);
    font-size: 0.5rem;
    margin-left: 9%;
  }

}

@media (min-width: 360px) and (max-width: 600px) {

  .summon-section-container {
    min-height: 40vw;
    max-height: calc(70vh - 10% - 3rem);
  }

  .summon-section-container h2 {
    font-size: 1.5rem;
  }

  .summon-section-container p {
    font-size: 0.5rem;
  }

  .circle-buttons-container button {
    font-size: 1rem;
    background: none;
    color: rgba(246, 248, 221, 0.849);
    border: none;
    cursor: pointer;
    margin-bottom: 1rem;
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    white-space: nowrap;
  }

  .circle-buttons-container button::before {
    content: '•';
    position: absolute;
    left: 0;
    color: rgba(246, 248, 221, 0.849);
    font-size: 1rem;
    margin-left: 9%;
  }
}

@media (min-width: 601px) and (max-width: 1279px) {

  .summon-section-container {
    min-height: 40vw;
    max-height: calc(70vh - 10% - 3rem);
  }

  .summon-section-container h2 {
    font-size: 3rem;
  }

  .summon-section-container p {
    font-size: 1rem;
  }

  .circle-buttons-container button {
    font-size: 2rem;
    background: none;
    color: rgba(246, 248, 221, 0.849);
    border: none;
    cursor: pointer;
    margin-bottom: 1rem;
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    white-space: nowrap;
  }

  .circle-buttons-container button::before {
    content: '•';
    position: absolute;
    left: 0;
    color: rgba(246, 248, 221, 0.849);
    font-size: 2rem;
    margin-left: 9%;
  }

}

@media (min-width: 1280px) {

  .summon-section-container {
    min-height: 40vw;
    max-height: calc(70vh - 10% - 3rem);
  }

  .summon-section-container h2 {
    font-size: 4.5rem;
  }

  .summon-section-container p {
    font-size: 1.5rem;
  }

  .circle-buttons-container button {
    font-size: 3.5rem;
    background: none;
    color: rgba(246, 248, 221, 0.849);
    border: none;
    cursor: pointer;
    margin-bottom: 1rem;
    font-family: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    white-space: nowrap;
  }

  .circle-buttons-container button::before {
    content: '•';
    position: absolute;
    left: 0;
    color: rgba(246, 248, 221, 0.849);
    font-size: 3.5rem;
    margin-left: 9%;
  }

}