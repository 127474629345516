.flipbook-container p {
    font-family: 'car', sans-serif;
}

.flip-page-odd {
    background-image: url(./images/LoreBook/Grim_Web_Page_01.png);
}

.flip-page-even {
    background-image: url(./images/LoreBook/Grim_Web_Page_01_Flipped.png);
}

.page-content {
    font-size: 2.25rem;
    padding: 5rem 4rem;
}

.main-page {
    font-size: 3.5rem;
}

.text-xl {
    font-size: 4.5rem;
}

.h1 {
    margin-bottom: 2.5rem;
}

.page-content img {
    max-width: 100% !important;
}

.ele-image {
    width: 600px;
    height: 600px;
}

.dec-image {
    margin-top: 60px;
    width: 450px;
    height: 450px
}

.sac-image {
    width: 550px;
    height: 550px;
}

.circles-font {
    font-size: 4.5rem;
}

.lore-font {
    font-size: 2.5rem;
}

@media (max-width: 500px) {
    .book-image-container {
        transform: scale(.2);
    }
}

@media (min-width: 500px) and (max-width: 799px) {
    .book-image-container {
        transform: scale(.3);
    }
}

@media (min-width: 800px) and (max-width: 949px) {
    .book-image-container {
        transform: scale(.5);
    }
}

@media (min-width: 950px) and (max-width: 1279px) {
    .book-image-container {
        transform: scale(.6);
    }
}


@media (min-width: 1280px) and (max-width: 1699px) {
    .book-image-container {
        transform: scale(.8);
    }
}

@media (min-width: 1700px) {
    .book-image-container {
        transform: scale(1);
    }
}