.footer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #000000;
    border-top: .5vh solid;
    border-image: radial-gradient(circle at center, gold 0%, #000000 90%);
    border-image-slice: 1;
}

.footer img {
    width: auto;
    height: 3vw;
    cursor: pointer;
}