.intro-section-container {
    position: relative;
    padding-top: 3vw;
    padding-bottom: 3vw;
    scroll-behavior: smooth;
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: 50vw;
    background-color: #000000;
    z-index: 2;
    border-top: .5vh solid;
    border-image: radial-gradient(circle at center, gold 0%, #000000 90%);
    border-image-slice: 1;
    padding-top: 3vh;
    padding-bottom: 3vh;

}

.intro-section-container::before {
    content: "";
    background-image: url("./images/GreyLeather.png");
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
    pointer-events: none;
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    filter: brightness(.1);
    mask-image: radial-gradient(circle at center, black 30%, transparent 90%);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
}

.intro-section-container::after {
    content: "";
    background-image: url("./images/ritual\ in\ the\ woods.png");
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translateY(-50%);
    height: 30vw;
    width: 30vw;
    max-width: 500px;
    max-height: 500px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1;
    mask-image: radial-gradient(circle at center, black 40%, transparent 70%);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
}

.intro-text {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    z-index: 2;
    color: rgb(191, 183, 147, 0.849);
    text-align: left;
    width: 55%;
}

.intro-text h1 {
    color: rgb(246, 248, 221, 0.849);
}

@media (max-width: 359px) {

    .intro-section-container {
        min-height: 50vw;
    }

    .intro-section-container::after {
        left: 5%;
    }

    .intro-text h1 {
        font-size: .75rem;
    }

    .intro-text p {
        font-size: 0.25rem;
        max-width: -50%;
    }
}

@media (min-width: 360px) and (max-width: 600px) {
    .intro-section-container {
        min-height: 50vw;
    }

    .intro-section-container::after {
        left: 5%;
    }

    .intro-text h1 {
        font-size: 1.5rem;
    }

    .intro-text p {
        font-size: 0.5rem;
        max-width: -50%;
    }
}

@media (min-width: 601px) and (max-width: 1279px) {
    .intro-section-container {
        min-height: 50vw;
        max-height: 50vw;
    }

    .intro-section-container::after {
        left: 5%;
    }

    .intro-text h1 {
        font-size: 3rem;
    }

    .intro-text p {
        font-size: .75 rem;
        max-width: -50%;
    }
}

@media (min-width: 1280px) {

    .intro-section-container {
        min-height: 50vw;
        max-height: 50vw;
    }

    .intro-section-container::after {
        left: 5%;
    }

    .intro-text h1 {
        font-size: 4.5rem;
    }

    .intro-text p {
        font-size: 1.5rem;
        max-width: -50%;
    }
}