.grim-pfp-section-container {
  position: relative;
  min-height: 40vw;
  background-color: #000000;
  z-index: 1;
  padding-top: 3vh;
  padding-bottom: 3vh;
}


.portal-bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transform: translate(-30vw, -30vw);
  mask-image: radial-gradient(circle at center, black 40%, transparent 70%);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}


.content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
}


.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 20%;
  padding-right: 5%;
}

.grim-10k {
  font-size: 6rem;
  color: rgb(246, 248, 221, 0.849);
  text-align: center;
}

.grim-mint-desc {
  font-size: 4rem;
  color: rgb(191, 183, 147, 0.849);
  text-align: center;
}

.pfp-image-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 5px;
  padding: 15% 10%;
}

.pfp-image-container img {
  width: 10vw;
  height: auto;
  mask-image: radial-gradient(circle at center, black 40%, transparent 80%);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;

}

@media (max-width: 359px) {
  .grim-10k {
    font-size: 0.75rem;
  }

  .grim-mint-desc {
    font-size: 0.25rem;
  }

}

@media (min-width: 360px) and (max-width: 600px) {
  .grim-10k {
    font-size: 1.5rem;
  }

  .grim-mint-desc {
    font-size: .5rem;
  }

}

@media (min-width: 601px) and (max-width: 1279px) {
  .grim-10k {
    font-size: 3rem;
  }

  .grim-mint-desc {
    font-size: 1rem;
  }


}

@media (min-width: 1280px) {
  .grim-10k {
    font-size: 4.5rem;
  }

  .grim-mint-desc {
    font-size: 1.5rem;
  }

}